@import "../../variables.scss";
.biog-page {
  padding-bottom: 60px;

  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  &__image {
    width: 30%;
    position: sticky;
    top: 40px;
    height: 100%;
    & img {
      width: 100%;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 768px) {
      width: 100%;
      position: relative;
      top: 0;
      margin-bottom: 30px;
    }
  }

  &__text {
    flex: 1;
    margin: 0 20px;
    padding: 30px;
  }

  &__option {
    // color: rgba($greyText, 0.9);
    text-decoration: none;
    border: none;
    width: 100%;
    // border-bottom: 1px solid rgba($greyText, 0.6);
    line-height: 33px;
    padding-top: 7px;
    display: block;
    opacity: 1;
    transition: 300ms;
    text-align: left;
    letter-spacing: 0.07rem;
    font-size: 16px;
    background-color: transparent;

    &--active {
      font-weight: bold;
      // border-bottom: 2px solid rgba($greyText, 0.6);
    }

    &:hover {
      // background-color: $hoverGrey;
    }
  }
}
