@import "../../variables.scss";

.agenda {
  &__item {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    border-bottom: 1px solid $primary;
    margin: 16px auto;
    padding: 20px;
    width: 60%;
    min-width: 700px;
    max-width: 900px;

    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column-reverse;
      padding-bottom: 70px;
      min-width: unset;
    }
  }

  &__image {
    width: 30%;
    min-width: 200px;
    aspect-ratio: 1/1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 768px) {
      min-width: unset;
      width: 100%;
    }
  }

  &__date {
    font-weight: 300;
  }

  &__time {
    color: $primary;
    font-size: 18px;
    font-weight: bold;
  }

  &__title {
    font-weight: bold;
    font-size: larger;
  }

  &__desc {
    font-weight: 300;
    padding-bottom: 30px;
  }
}
