.contact {
  &__group-foto {
    width: 100%;
    height: 50vh;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    margin-top: 40px;
    @media (max-width: 768px) {
      height: 30vh;
    }
  }

  &__agent-container {
    margin: 30px 0;
    padding: 30px;
  }

  &__details {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      padding-top: 40px;
    }
  }

  &__socials {
    padding-bottom: 50px;
  }
}
