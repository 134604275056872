@import "../../variables.scss";

.recording-card {
  display: flex;
  justify-content: space-between;
  padding: 40px 30px;
  max-width: 1000px;
  margin: auto;
  border-bottom: 1px solid $primary;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 768px) {
      margin: 26px;
      align-items: center;
    }
  }

  &__title {
    margin-bottom: 24px;
    @media (max-width: 768px) {
      text-align: center;
    }
  }

  &__image {
    width: 400px;
    img {
      width: 100%;
    }
  }
}
