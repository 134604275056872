@import "../../variables.scss";

.youtube {
  position: relative;
  &__overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    animation: fadeIn 300ms;
    animation-fill-mode: both;
  }

  &__title {
    color: white;
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
  }
  &__video {
    position: relative;
    aspect-ratio: 16/9;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
  &__button {
    padding: 8px;
    border: none;
    color: $primary;
    background-color: rgba(white, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    margin: 12px auto;
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
