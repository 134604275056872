@import "../../variables.scss";

.socials {
  display: flex;
  justify-content: center;
  &__icon {
    margin: 0 16px;
    transition: 300ms;

    &:hover {
      color: $hoverColour;
      cursor: pointer;
    }
  }
}
