.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  margin: 0 16px;
  @media (min-width: 1130px) {
    display: none;
  }
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__line {
  width: 30px;
  height: 2px;
  background: white;
}

.toggle-button:hover {
  transform: rotate(90deg);
  transition: 500ms;
}
