@import "../../variables.scss";
.flag {
  border: none;
  cursor: pointer;
  color: $primary;
  transition: 300ms;
  font-size: 0.8rem;

  &:hover {
    background-color: $hoverColour;
    color: white;
  }
}
