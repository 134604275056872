.side-drawer {
  height: 100%;
  background-color: steelblue;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 300px;
  z-index: 200;
  transform: translateX(-100%);
  transition: 300ms ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer li {
  margin-bottom: 20px;
}

.side-drawer ul {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.drop-down {
  color: whitesmoke;
}

.side-drawer a {
  transition: 300ms;
  color: whitesmoke;
  text-decoration: none;
}

.dropdown-item a {
  color: whitesmoke;
}
.side-drawer a:hover,
.side-drawer a:active {
  color: goldenrod;
}

.header {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(235, 210, 192);
}

.mechelen-logo {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  width: 70%;
  margin: auto;
}

.social {
  color: rgb(235, 210, 192);
  display: flex;
  justify-content: space-around;
}

.i18n {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
