.people-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 26px;

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  &--cols1 {
    grid-template-columns: 1fr;
    max-width: 50%;
    margin: auto;
  }

  &--cols2 {
    grid-template-columns: 1fr;

    @media (min-width: 900px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &--cols3 {
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  &--cols4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
