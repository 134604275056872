.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  z-index: 500;
  background-color: black;

  &--leaving {
    transition: opacity 500ms;
    opacity: 0;
  }

  &__button {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    width: 100%;
  }

  &__video {
    position: absolute;
    inset: 0;
    height: 100dvh;
    width: 100vw;
    object-fit: cover;
    animation: fadeIn 300ms ease-in;
    animation-fill-mode: both;
    animation-delay: 300ms;

    &__overlay {
      position: absolute;
      z-index: 100;
      background-color: rgba(0, 0, 0, 0.7);
      inset: 0;
    }
  }

  &__logo {
    width: 60%;
    max-width: 300px;
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 550;
    animation: fadeIn 500ms ease-in-out;
    animation-fill-mode: both;
    animation-delay: 2s;

    text-align: center;

    img {
      width: 100%;
      margin-bottom: 24px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
