.recording-details {
  padding: 30px;

  &__cover {
    width: 30%;
    min-width: 300px;

    margin: auto;
    img {
      width: 100%;
    }
  }
  &__title {
    margin: 40px auto;
    width: fit-content;
  }

  &__text {
    width: 50%;
    min-width: 300px;
    margin: auto;
  }

  &__audio-container {
    display: flex;
    justify-content: space-evenly;
    margin: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__audio-item {
    margin: 16px;
    small {
      display: block;
      margin-bottom: 10px;
    }
  }
}
