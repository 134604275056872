@import "../../variables.scss";

.programme__item {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  max-width: 1000px;
  margin: auto;
  padding: 40px 20px;
  border-bottom: 1px solid $primary;

  @media (min-width: 769px) {
    flex-direction: row;
  }

  &__title {
    margin-bottom: 40px;
  }

  &__left {
    flex: 1;
    color: $primary;
  }

  &__link {
    color: inherit;
    transition: 300ms;
    &:hover {
      color: $hoverColour;
    }
  }

  &__image {
    width: 100%;
    aspect-ratio: 4/3;
    overflow: hidden;
    object-position: center;
    margin-bottom: 16px;

    @media (min-width: 769px) {
      width: 50%;
      margin-bottom: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-position: top;
      object-fit: cover;
    }
  }
}
