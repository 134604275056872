.top-menu {
  display: none;
  @media (min-width: 1130px) {
    display: block;
  }
  &__nav {
    list-style: none;
    display: flex;
    align-items: baseline;
    margin-bottom: -10px;
  }
  &__link {
    margin-right: 16px;
    color: whitesmoke;

    &--active {
      text-decoration: underline;
      color: white !important;
    }
  }
}
