@import "../../variables.scss";

.programme-details {
  padding-bottom: 60px;
  &__image {
    position: relative;

    width: 100%;
    max-height: 80vh;
    overflow: hidden;
    z-index: 3;
    margin-bottom: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.2;
    }
  }

  &__title {
    color: $primaryDark;
    font-size: 1.8rem;
    text-align: center;
    margin: 36px 0;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: 0.3rem;
    font-size: 2.7rem;
  }

  &__content {
    width: 80%;
    margin: auto;
    iframe {
      display: block;
      margin: 30px auto;
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;

      @media (min-width: 768px) {
        width: 60%;
      }
    }
  }
}
