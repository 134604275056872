.lpm {
  &__image {
    // min-width: 100%;
    min-width: 100%;
    max-height: 80vh;
    overflow: hidden;
    object-position: center;

    img {
      width: 100%;
      height: 100%;
      // object-fit: contain;
      // object-position: center;
    }
  }
  &__text {
    padding: 40px;
    @media (min-width: 900px) {
      columns: 2;
      column-gap: 2rem;
      -moz-columns: 2;
    }
  }
}
