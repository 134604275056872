@import "./variables.scss";

h1,
h2,
h3,
a {
  color: $primary;
}

a:hover {
  transition: 200ms;
  color: $hoverColour;
}

.link-cta {
  border: 1px solid $primary;
  padding: 8px;
  transition: 300ms;
  cursor: pointer;
  color: $primary;

  &:hover {
    background-color: rgba($primary, 0.2);
  }

  &--dark {
    background-color: $primary;
    color: white;
  }
}

.title-bar {
  color: $primary;
  // max-width: 1400px;
  margin: 55px auto;
  padding: 30px;
  h1 {
    font-size: 2.5rem;
    font-weight: 300;
  }
  position: sticky;
  top: 54px;
  display: flex;
  justify-content: space-between;
  z-index: 250;
  background-color: rgba(231, 246, 253, 0.6);
  backdrop-filter: blur(5px);

  @media (max-width: 768px) {
    padding: 10px;
    padding-left: 20px;
    background-color: rgba(231, 246, 253, 0.9);
    backdrop-filter: blur(20px);
    h1 {
      font-size: 2rem;
    }
  }

  &__socials {
    text-align: right;
  }
}

.view {
  min-height: 100vh;
  animation: fadeIn 700ms;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
